export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
}

export enum VerificationMethod {
  No2FA = 0,
  EmailOTP = 1,
  SMSOTP = 2,
  TOTP = 3,
}
export enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  DEFAULT = "default",
}

export enum AUTH_METHOD {
  LOGIN = 0,
  SIGNUP = 1,
}

export enum PriceBy {
  Unit = 0,
  Kg = 1,
}

export enum ConditionType {
  MinOrderAmount = 0,
  DeliveryTime = 1,
}

export enum PaymentMethod {
  Cash = 1,
  CreditCard = 2,
}
export enum OrderStatus {
  Pending = 0, // בביצוע
  Confirmed = 1, // אושרה
  Cancelled = 2, // בוטלה
}

export enum OrderPaymentStatus {
  Unpaid = 0, // טרם שולם
  Paid = 1, // שולם
  Failed = 2, // נכשל
}

export enum OrderShipmentStatus {
  Pending = 0, // בהכנה
  Prepared = 1, // מוכן
  OutForDelivery = 2, // במרכז הפצה
  Delivered = 3, // נמסר
}

export enum CouponDiscountType {
  Percentage = 0,
  FixedAmount = 1,
  FreeShipping = 2,
  PercentageAndFreeShipping = 3,
  FixedAmountAndFreeShipping = 4,
}
export enum DispatchMethod {
  SMS = 0,
  Email = 1,
}
export enum SellType {
  Unit = 0, // "יח'"
  KG = 1, // "ק\"ג"
  Package = 2, // "חבילה"
}
export enum AnimationType {
  NONE,
  FIRE,
  PULSE,
}
export enum CategoryProductsType {
  DEFAULT,
  DISCOUNT,
}

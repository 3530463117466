export const AUTH_ACTION_TYPES = {
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
};

export const CART_ACTION_TYPES = {
  ADD_ITEM_WITH_QUANTITY: "ADD_ITEM_WITH_QUANTITY",
  REMOVE_ITEM_OR_QUANTITY: "REMOVE_ITEM_OR_QUANTITY",
  REMOVE_ITEM: "REMOVE_ITEM",
  CLEAR_CART: "CLEAR_CART",
  UPDATE_CART: "UPDATE_CART",
  FETCH_CART: "FETCH_CART",
  FETCH_CART_V3: "FETCH_CART_V3",
  SAVE_NOTE: "SAVE_NOTE",
  UPDATE_SELL_TYPE: "UPDATE_SELL_TYPE",
};

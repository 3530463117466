const CUSTOMERS_BASE = "customers";
export const CUSTOMERS_URLS = {
  Register: `${CUSTOMERS_BASE}/register`,
  ResendVerification: `${CUSTOMERS_BASE}/ResendVerification`,
  VerifyRegistration: `${CUSTOMERS_BASE}/VerifyRegistration`,
  Login: `${CUSTOMERS_BASE}/Login`,
  VerifyLogin: `${CUSTOMERS_BASE}/VerifyLogin`,
  UpdatePhone: `${CUSTOMERS_BASE}/UpdatePhone`,
  VerifyUpdatePhone: `${CUSTOMERS_BASE}/VerifyUpdatePhone`,
  UpdateProfile: `${CUSTOMERS_BASE}/UpdateProfile`,
};

const CUSTOMERS_SHIPMENT_BASE = "customerShipmentInfos";
export const CUSTOMERS_SHIPMENT_URLS = {
  Create: `${CUSTOMERS_SHIPMENT_BASE}/Create`,
  Delete: `${CUSTOMERS_SHIPMENT_BASE}/Delete`,
  GetAll: `${CUSTOMERS_SHIPMENT_BASE}/GetAll`,
  Update: `${CUSTOMERS_SHIPMENT_BASE}/Update`,
};

const PRODUCTS_BASE = "products";
export const PRODUCTS_URLS = {
  GetAllDT: `${PRODUCTS_BASE}/Search`,
};

const ORDERS_BASE = "orders";
export const ORDERS_URLS = {
  GetMyOrders: `${ORDERS_BASE}/GetMyOrders`,
  Get: `${ORDERS_BASE}/get`,
  Create: `${ORDERS_BASE}/Create`,
  ConfirmWithCashPaymentMethod: `${ORDERS_BASE}/ConfirmWithCashPaymentMethod`,
  GetPaymentEmbeddedFrame: `${ORDERS_BASE}/GetPaymentEmbeddedFrame`,
  PeriodicalConfirmationCheck: `${ORDERS_BASE}/PeriodicalConfirmationCheck`,
};

const USERS_BASE = "Users";
export const USERS_URLS = {
  EditProfile: `${USERS_BASE}/EditProfile`,
  GetProfile: `${USERS_BASE}/GetProfile/`,
};
const CATEGORIES_BASE = "categories";
export const CATEGORIES_URLS = {
  GetTree: `${CATEGORIES_BASE}/GetTree`,
};
const SHIPMENT_METHODS_BASE = "shipmentMethods";
export const SHIPMENT_METHODS_URLS = {
  GetAll: `${SHIPMENT_METHODS_BASE}/getAll`,
};

const GATEWAY_BASE = "gateway";
export const GATEWAY_URLS = {
  GetPreferences: `${GATEWAY_BASE}/GetPreferences`,
  GetCities: `${GATEWAY_BASE}/GetCities`,
  GetCityStreets: `${GATEWAY_BASE}/GetCityStreets`,
  CheckCityAvailability: `${GATEWAY_BASE}/CheckCityAvailability`,
  SubscribeToNewletter: `${GATEWAY_BASE}/SubscribeToNewletter`,
};
const CONTACTUS_MESSAGES_BASE = "ContactUsMessages";
export const CONTACTUS_METHODS_URLS = {
  SendMessage: `${CONTACTUS_MESSAGES_BASE}/SendMessage`,
};
const EVENTS_BASE = "events";
export const EVENTS_URLS = {
  ViewProduct: `${EVENTS_BASE}/ViewProduct`,
  AddProductToCart: `${EVENTS_BASE}/AddProductToCart`,
};
const COUPONS_BASE = "coupons";
export const COUPONS_URLS = {
  IsEligibleForRedemption: `${COUPONS_BASE}/IsEligibleForRedemption`,
};

import { AnimationType, SellType as SellTypeEnum } from "@/utils/Enums";
import { SellType as SellTypeDTO } from "@/types";

export type SellType = {
  type: SellTypeEnum;
  step: number;
  quantityComparingToDefaultSellType: number;
};
export type ProductSearch = {
  productsIds?: number[];
  searchTerm?: string;
  isHomePageProducts?: boolean;
  categoriesIds?: string[];
  brandsIds?: string[];
  pageNumber?: number;
  pageSize?: number;
};
export type ProductCategoryProps = {
  id: string;
  name: string;
  iconImgURL: string;
};

export enum CampaignType {
  Discount,
  BuyXQuantityForTotalDiscountY,
  BuyXQuantityGetYQuantityWithDiscountZ,
}
export enum IDiscountType {
  Percentage,
  FixedAmount,
  NewPrice,
}
export type IDiscountRepresentation = {
  mainTitle: string;
  secondaryTitle: string;
};
export type CampaignItem = {
  campaignId: number;
  campaignItemId: number;
  campaignType: CampaignType;
  campaignData: ICampaignItemData;
  productSellType?: SellTypeEnum;
  representableTexts: IDiscountRepresentation;
};
export type ICampaignItemData = {
  conditionalQuantity: number;
  discountType: IDiscountType;
  discountValue: number;
  maxDuplicates: number | null;
  receivedQuantity: number;
};

export type ITag = {
  id: number;
  name: string;
  isMain: boolean;
  backgroundColor: string;
  textColor: string;
  animationType: AnimationType;
};
export type Product = {
  id: number;
  title: string;
  subTitle?: string;
  description?: string;
  mainImg?: string;
  images?: string[];
  inventoryManagementLevel: number;
  pricingLevel: number;
  price: number;
  oldPrice?: number;
  quantity?: number; //TODO
  discountPrice?: number; //TODO
  campaignDiscount?: { CPQ: number; maxDuplicates: number | null }; //TODO Campaign Product Quantity
  sku?: number | string;
  barcode?: number | string;
  isVATExcluded?: boolean;
  sellType?: SellTypeDTO;
  defaultSellType: SellType;
  additionalSellTypes?: SellType[];
  note?: string;
  productProperties?: any;
  brand?: any;
  categories?: ProductCategoryProps[];
  campaignItems: CampaignItem[];
  tags: ITag[];
  notes?: string[];
  propertiesDefinitions?: any[];
};

export type ProductProps = {
  product: Product;
  quantity?: number | undefined;
  showAnimation?: boolean;
  onClick?: (id: number | string) => void;
};

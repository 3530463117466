import apiCall from "@/network/apiCall";
import { PRODUCTS_URLS } from "@/network/api";
import { RequestMethod } from "@/utils/Enums";
import { Product, ProductSearch } from "@/types/dtos/products";

const ProductsRepository = () => {
  const getProducts = async (objectBody: ProductSearch): Promise<Product[]> => {
    const data = await apiCall(
      PRODUCTS_URLS.GetAllDT,
      RequestMethod.POST,
      objectBody
    );
    return data;
  };

  return { getProducts };
};

const productsRepository = ProductsRepository();
export default productsRepository;

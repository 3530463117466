import apiCall from "@/network/apiCall";
import { EVENTS_URLS } from "@/network/api";
import { RequestMethod } from "@/utils/Enums";
import { AddProductToCartDto } from "@/types/dtos/events";

const EventsRepository = () => {
  const viewProduct = async (productId: number) => {
    const data = await apiCall(EVENTS_URLS.ViewProduct, RequestMethod.POST, {
      productId,
    });
    return data;
  };

  const addProductToCart = async (objectData: AddProductToCartDto) => {
    const data = await apiCall(
      EVENTS_URLS.AddProductToCart,
      RequestMethod.POST,
      objectData
    );
    return data;
  };
  return { addProductToCart, viewProduct };
};

const eventsRepository = EventsRepository();
export default eventsRepository;

// "use server";
import { getSession } from "@/utils/session";
import axios from "axios";
import { isMobile, isTablet, browserName } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";
import appSettings from "../../appsettings.json";
const { general } = appSettings;
const { VendorCode, version } = general;
const apiUrl = process.env.NEXT_PUBLIC_API_URL;

const deviceTypeHeader = isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop";
const deviceNameHeader = browserName;

function getGuestId() {
  if (typeof window !== "undefined" && localStorage) {
    let guestId = localStorage.getItem("guestId");

    if (!guestId) {
      guestId = uuidv4();
      localStorage.setItem("guestId", guestId);
    }

    return guestId;
  }
  return null;
}
export default async function HttpRequest(
  url,
  method,
  data,
  contentType = "application/json",
  lang,
  token
) {
  let session = getSession();

  let _token = token || session?.token;
  const guestId = getGuestId();
  return axios
    .request({
      url: url,
      baseURL: apiUrl,
      method: method,
      data: data,
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${_token}`,
        "Accept-Language": "he",
        "Vendor-Code": VendorCode,
        "Guest-Identifier": guestId,
        // "Guest-Identifier": session ? guestId : null,
        "Frontend-Platform": "W",
        "Frontend-Platform-Version": version,
        "Device-Name": deviceNameHeader,
        "Device-Type": deviceTypeHeader,
      },
    })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      if (err?.response && err?.response?.status === 401) {
        // eventEmitter.emit("unauthorizedError");
      }

      return Promise.reject(err.response);
    });
}

import { CART_ACTION_TYPES } from "@/constants/actionTypes";
import { Product, SellType } from "@/types/dtos/products";

const {
  ADD_ITEM_WITH_QUANTITY,
  REMOVE_ITEM_OR_QUANTITY,
  REMOVE_ITEM,
  CLEAR_CART,
  FETCH_CART_V3,
  SAVE_NOTE,
  UPDATE_SELL_TYPE,
} = CART_ACTION_TYPES;

const CartActions = () => {
  const addItemToCart = (item: Product, quantity: number) => {
    return { type: ADD_ITEM_WITH_QUANTITY, payload: { item, quantity } };
  };
  const removeItemFromCart = (
    id: number,
    quantity: number,
    sellType: SellType
  ) => {
    return async (dispatch: any) => {
      dispatch({
        type: REMOVE_ITEM_OR_QUANTITY,
        payload: { id, quantity, sellType },
      });
    };
  };
  const clearItemFromCart = (id: number, sellType: SellType) => {
    return async (dispatch: any) => {
      dispatch({ type: REMOVE_ITEM, payload: { id, sellType } });
    };
  };
  const updateItemSellType = (
    id: number,
    sellType: SellType,
    quantity: number
  ) => {
    return async (dispatch: any) => {
      dispatch({ type: UPDATE_SELL_TYPE, payload: { id, sellType, quantity } });
    };
  };
  const clearCart = () => {
    return async (dispatch: any) => {
      dispatch({ type: CLEAR_CART });
    };
  };
  const saveNote = (id: number, sellType: SellType, note: string) => {
    return async (dispatch: any) => {
      dispatch({ type: SAVE_NOTE, payload: { id, sellType, note } });
    };
  };
  const fetchCartV3 = (cart: { cart: { items: Product[]; total: number } }) => {
    return { type: FETCH_CART_V3, payload: cart };
  };

  return {
    addItemToCart,
    removeItemFromCart,
    clearItemFromCart,
    clearCart,
    updateItemSellType,
    saveNote,
    fetchCartV3,
  };
};

export default CartActions;

import { useMemo } from "react";

export function formatPrice({ amount, currencyCode, locale }) {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  });

  return formatCurrency.format(amount);
}

export function formatVariantPrice({
  amount,
  baseAmount,
  currencyCode,
  locale,
}) {
  const hasDiscount = baseAmount > amount;
  const formatDiscount = new Intl.NumberFormat(locale, { style: "percent" });
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null;

  const price = formatPrice({ amount, currencyCode, locale });
  const basePrice = hasDiscount
    ? formatPrice({ amount: baseAmount, currencyCode, locale })
    : null;

  return { price, basePrice, discount };
}

export default function usePrice(data) {
  const { amount, baseAmount, currencyCode = "ILS" } = data ?? {};
  const locale = "en";
  const value = useMemo(() => {
    if (typeof amount !== "number" || !currencyCode) return "";
    return baseAmount
      ? formatVariantPrice({ amount, baseAmount, currencyCode, locale })
      : formatPrice({ amount, currencyCode, locale });
  }, [amount, baseAmount, currencyCode]);

  return typeof value === "string"
    ? { price: value, basePrice: null, discount: null }
    : value;
}

export function usePrice2(input, defaultCurrencyCode = "ILS") {
  const locale = "en";
  const processPrice = (data) => {
    const {
      amount,
      baseAmount,
      currencyCode = defaultCurrencyCode,
      quantity = 1,
      multiplier = 1,
    } = data;

    if (typeof amount !== "number" || !currencyCode) return "";

    const adjustedAmount = amount * quantity * multiplier;
    const adjustedBaseAmount = baseAmount
      ? baseAmount * quantity * multiplier
      : null;

    return baseAmount
      ? formatVariantPrice({
          amount: adjustedAmount,
          baseAmount: adjustedBaseAmount,
          currencyCode,
          locale,
        })
      : formatPrice({ amount: adjustedAmount, currencyCode, locale });
  };
  const value = useMemo(() => {
    if (Array.isArray(input)) {
      return input.reduce((result, item) => {
        const key = item.key || `price_${result.length}`;
        result[key] = processPrice(item);
        return result;
      }, {});
    }

    if (typeof input === "object") {
      return processPrice(input);
    }

    return "";
  }, [input]);

  if (Array.isArray(input)) {
    return value; // Return an object of keyed results
  }

  return typeof value === "string"
    ? { price: value, basePrice: null, discount: null }
    : value; // Return the processed single price object
}
// TODO
// const prices = usePrice2([
//   {
//     key: "productTotalPrice",
//     amount:
//       product?.price * quantityComparingToDefaultSellType * quantity || 0,
//   },
//   {
//     key: "productPrice",
//     amount: product?.price * quantityComparingToDefaultSellType,
//     baseAmount: (product?.oldPrice || 0) * quantityComparingToDefaultSellType,
//   },
// ]);

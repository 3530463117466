import useFetch from "@/hooks/useFetch";
import { PaginationResponse } from "@/hooks/usePagination";
import productsRepository from "@/repository/products-repository";
import { Product, ProductSearch } from "@/types/dtos/products";
const ProductsActions = () => {
  const { handleApiResponse } = useFetch();
  const getProducts = (
    objectBody: ProductSearch
  ): Promise<PaginationResponse<Product>> => {
    return handleApiResponse({
      showSuccessToast: false,
      showErrorToast: false,
      callback: () => productsRepository.getProducts(objectBody),
    });
  };

  return { getProducts };
};

export default ProductsActions;

import useGlobalData from "@/hooks/useGlobalData";
import { useEffect, useState } from "react";
const useProductSellType = ({
  defaultSellType,
  additionalSellTypes,
  sellType = null,
}) => {
  const { enums } = useGlobalData();
  const [currentSellType, setCurrentSellType] = useState(defaultSellType);
  const [productSellTypes, setProductSellTypes] = useState([]);

  const { step, quantityComparingToDefaultSellType = 1 } =
    currentSellType || {};

  const handleSelectSellType = (sellType, quantity) => {
    if (sellType.type === currentSellType.type) {
      return quantity;
    }

    let convertedQuantity;
    const roundToStep = (value, step) => {
      return Math.floor(value / step) * step;
    };

    if (sellType.type === defaultSellType.type) {
      convertedQuantity = quantity * quantityComparingToDefaultSellType;
      convertedQuantity = roundToStep(convertedQuantity, sellType.step);
    } else {
      convertedQuantity =
        quantity / sellType.quantityComparingToDefaultSellType;
      convertedQuantity = roundToStep(convertedQuantity, sellType.step);
    }

    setCurrentSellType(sellType);
    return convertedQuantity || 1;
  };

  useEffect(() => {
    const additionalSellType = additionalSellTypes?.[0];
    let defaultType;
    const sellTypes = enums?.sellType.reduce((acc, type) => {
      if (type.value == defaultSellType?.type) {
        defaultType = { ...type, ...defaultSellType };
        acc.push(defaultType);
      }
      if (type.value == additionalSellType?.type) {
        acc.push({ ...type, ...additionalSellType });
      }
      return acc;
    }, []);

    setProductSellTypes(sellTypes);

    setCurrentSellType(sellType || defaultType);
  }, [defaultSellType, enums, sellType]);
  return {
    step,
    quantityComparingToDefaultSellType,
    currentSellType,
    productSellTypes,
    handleSelectSellType,
  };
};
export default useProductSellType;

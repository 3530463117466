import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const useToast = () => {
  const { t } = useTranslation("common");
  const showToast = (type, text, props) => {
    toast[type](t(text), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        textAlign: "right",
        direction: "rtl",
      },
      ...props,
      //   transition: Bounce,
    });
  };
  return { showToast };
};

export default useToast;

import { CategoryProductsType } from "@/utils/Enums";
import { useSelector } from "react-redux";
const findFirstCategoryWithDiscountProducts = (categories) => {
  for (const category of categories) {
    // Check if the current category has productsType = 1
    if (category.productsType === CategoryProductsType.DISCOUNT) {
      return category;
    }

    // If the category has subCategories, recursively search them

    // if (category.subCategories && category.subCategories.length > 0) {
    //   const found = findFirstCategoryWithProductsTypeOne(
    //     category.subCategories
    //   );
    //   if (found) {
    //     return found;
    //   }
    // }
  }
  return null;
};
const useGlobalData = () => {
  const globalData = useSelector((state) => state.globalData?.data);
  const categories = useSelector((state) => state.globalData?.categories);
  const {
    isAuthenticated,
    isSuggestPlatformVersionUpgrade,
    vendorData,
    frontendSettings,
    settings,
    features,
    enums,
    homePageBanners,
  } = globalData || {};
  const firstCategoryWithDiscountProducts =
    findFirstCategoryWithDiscountProducts(categories || []);
  return {
    globalData,
    isAuthenticated,
    isSuggestPlatformVersionUpgrade,
    vendorData,
    frontendSettings,
    settings,
    features,
    enums,
    homePageBanners,
    categories,
    SaleCategory: firstCategoryWithDiscountProducts,
  };
};

export default useGlobalData;

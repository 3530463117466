import useFetch from "@/hooks/useFetch";
import eventsRepository from "@/repository/events-repository";
import { AddProductToCartDto } from "@/types/dtos/events";
const EventsActions = () => {
  const { handleApiResponse } = useFetch();
  const viewProduct = (productId: number) => {
    return handleApiResponse({
      showSuccessToast: false,
      showErrorToast: false,
      callback: () => eventsRepository.viewProduct(productId),
    });
  };
  const addProductToCart = (objectData: AddProductToCartDto) => {
    return handleApiResponse({
      showSuccessToast: false,
      showErrorToast: false,
      callback: () => eventsRepository.addProductToCart(objectData),
    });
  };

  return { viewProduct, addProductToCart };
};

export default EventsActions;

import { getCookie, setCookie, deleteCookie } from "cookies-next";

const sessionName = "orto-session";

export async function saveSession({ user, token, expiresAt }) {
  if (typeof window === undefined) {
    return null;
  }
  const expires = new Date(expiresAt);
  const session = { user, token, expiresAt };

  setCookie(sessionName, JSON.stringify(session), {
    expires: expires,
    path: "/",
    secure: process.env.NODE_ENV === "production",
  });
}

export function clearSession() {
  deleteCookie(sessionName, { path: "/" });
}

export const getSession = () => {
  const sessionCookie = getCookie(sessionName);

  return sessionCookie ? JSON.parse(sessionCookie) : null;
};

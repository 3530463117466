"use client";
import CartActions from "@/actions/cart";
import EventsActions from "@/actions/events";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useToast from "./useToast";
import { ToastType } from "@/utils/Enums";
import { useTranslation } from "react-i18next";
import { AddItemCampaignDiscount } from "@/lib/calculations/discountCalculator";
import { message } from "antd";

const useCart = () => {
  const eventsActions = EventsActions();
  const { items, totalPrices } = useSelector((state) => state?.cart);
  const cartActions = CartActions();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { t } = useTranslation("common");
  const addItemToCart = (item, quantity) => {
    const itemCart = items.find((product) => item.id === product.id);
    if (itemCart && itemCart.sellType.type !== item.sellType?.type) {
      showToast(ToastType.WARNING, t("text-warning-same-sell-type"));
      return;
    }
    if (!itemCart) {
      eventsActions.addProductToCart({
        productId: item.id,
        quantity,
        sellType: item.sellType?.type,
      });
    }
    const currentCartQuantity = itemCart?.quantity || 0;
    const totalQuantity = currentCartQuantity + quantity;

    // Calculate free items based on the total quantity in the cart
    const freeItems = AddItemCampaignDiscount(
      item,
      quantity,
      item.sellType,
      totalQuantity
    );
    if (freeItems && freeItems > 0) {
      message.open({
        top: 10,
        duration: 3,
        maxCount: 1,
        rtl: true,
        content: (
          <div className="flex flex-col text-lg items-center text-center flex-wrap">
            {/* <LuPartyPopper color="#fa911d" /> */}
            <span>
              🎉 מזל טוב! קיבלת את המבצע עבור{" "}
              <span className="font-bold">{item.title}</span>
            </span>
            <span className="block">{`הוספנו לך ${freeItems} יחידות נוספות ללא עלות! מהרו לנצל את ההטבה!`}</span>
          </div>
        ),
      });
    }
    // Add the base quantity and free items to the cart
    dispatch(cartActions?.addItemToCart(item, (freeItems || 0) + quantity));
  };

  const removeItemFromCart = (id, quantity, sellType) => {
    dispatch(cartActions?.removeItemFromCart(id, quantity, sellType));
  };

  const clearItemFromCart = (id, sellType) => {
    dispatch(cartActions?.clearItemFromCart(id, sellType));
  };

  const updateItemSellType = (id, sellType, quantity) => {
    dispatch(cartActions.updateItemSellType(id, sellType, quantity));
  };

  const clearCart = () => {
    dispatch(cartActions?.clearCart());
  };
  const saveNote = (id, sellType, note) => {
    dispatch(cartActions.saveNote(id, sellType, note));
  };

  const cartTotal = useMemo(() => {
    const totalItems = items
      ? items?.reduce((total, item) => total + (item?.quantity || 0), 0)
      : 0;
    return Math.floor(totalItems);
  }, [items]);

  const cartTotalDistinctItems = items ? items?.length : 0;

  // const cartFinalPrice = useMemo(() => {
  //   return items
  //     ? items?.reduce(
  //         (total, item) =>
  //           total +
  //           item?.quantity *
  //             item?.price *
  //             (item.sellType?.quantityComparingToDefaultSellType || 1),
  //         0
  //       )
  //     : 0;
  // }, [items]);

  const isEmpty = items ? items?.length <= 0 : 0;
  const createCartObject = (data) => {
    const { name, mainImg, price, id, sellType } = data || {};
    return {
      ...data,
      price: price,
      name: name,
      image: mainImg,
      sellType,
    };
  };

  return {
    items,
    totalPrices,
    total: totalPrices?.total || 0,
    addItemToCart,
    removeItemFromCart,
    clearItemFromCart,
    clearCart,
    cartTotal,
    // cartFinalPrice,
    isEmpty,
    createCartObject,
    saveNote,
    cartTotalDistinctItems,
    updateItemSellType,
  };
};

export default useCart;

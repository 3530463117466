import { useState } from "react";
import useToast from "./useToast";
import { ToastType } from "@/utils/Enums";

const { SUCCESS, ERROR } = ToastType;

const useFetch = () => {
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (callback) => {
    setLoading(true);
    try {
      const response = await callback();
      return response;
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };
  const handleApiResponse = async ({
    callback,
    showSuccessToast = true,
    showErrorToast = true,
    message = "performed-successfully",
  }) => {
    try {
      const response = await callback();

      if (showSuccessToast) {
        showToast(SUCCESS, message);
      }
      return response;
    } catch (error) {
      if (showErrorToast) {
        showToast(
          ERROR,
          error?.message || error?.data?.errorMessage || "שגיאה"
        );
      }
    }
  };

  return { loading, error, fetchData, handleApiResponse };
};

export default useFetch;

import { SellType } from "@/types";
import {
  CampaignType,
  ICampaignItemData,
  IDiscountType,
  Product,
} from "@/types/dtos/products";

const applyDiscount = (
  totalPrice: number,
  campaignData: ICampaignItemData,
  quantity: number
) => {
  const { discountType, discountValue } = campaignData;
  switch (discountType) {
    case IDiscountType.Percentage:
      return totalPrice * (1 - discountValue / 100);
    case IDiscountType.FixedAmount:
      return totalPrice - quantity * discountValue;
    case IDiscountType.NewPrice:
      return discountValue * quantity;
    default:
      return totalPrice;
  }
};
const applyBuyXForYDiscount = (
  totalPrice: number,
  campaignData: ICampaignItemData,
  price: number,
  quantity: number
) => {
  const { discountType, discountValue, conditionalQuantity, maxDuplicates } =
    campaignData;

  const discountGroups = Math.floor(quantity / conditionalQuantity);
  const eligibleSets = !maxDuplicates
    ? discountGroups
    : Math.min(discountGroups, maxDuplicates);

  if (eligibleSets > 0) {
    switch (discountType) {
      case IDiscountType.Percentage:
        const discountPrice =
          price * (discountValue / 100) * eligibleSets * conditionalQuantity;
        totalPrice -= discountPrice;
        break;
      case IDiscountType.FixedAmount:
        totalPrice -= eligibleSets * discountValue;
        break;
      case IDiscountType.NewPrice:
        totalPrice -=
          (price * conditionalQuantity - discountValue) * eligibleSets;
        break;
    }
  }
  return totalPrice;
};
const applyBuyXGetYDiscount = (
  totalPrice: number,
  campaignData: ICampaignItemData,
  price: number,
  quantity: number
) => {
  const {
    discountType,
    discountValue,
    conditionalQuantity,
    maxDuplicates,
    receivedQuantity,
  } = campaignData;

  const discountGroups = Math.floor(
    quantity / (conditionalQuantity + receivedQuantity)
  );
  const eligibleSets = !maxDuplicates
    ? discountGroups
    : Math.min(discountGroups, maxDuplicates);

  if (eligibleSets > 0) {
    switch (discountType) {
      case IDiscountType.Percentage:
        const discountPricePercentage =
          price * receivedQuantity * (discountValue / 100) * eligibleSets;
        totalPrice -= discountPricePercentage;
        break;

      case IDiscountType.FixedAmount:
        const discountPriceFixed = discountValue * eligibleSets;
        totalPrice -= discountPriceFixed;
        break;

      case IDiscountType.NewPrice:
        const regularPriceForReceived = price * receivedQuantity * eligibleSets;
        const discountedPriceForReceived = discountValue * eligibleSets;
        totalPrice -= regularPriceForReceived - discountedPriceForReceived;
        break;
    }
  }

  return totalPrice;
};
const DiscountCalculator = (
  product: Product,
  quantity: number,
  sellType?: SellType
) => {
  const { price, campaignItems, title } = product || {};
  const { quantityComparingToDefaultSellType, type } = sellType || {};

  const ItemTotalPrice =
    quantity * (price * (quantityComparingToDefaultSellType || 1));
  let hasDiscount = false;
  let campaignDiscount = undefined;
  let totalPrice = ItemTotalPrice;
  if (!campaignItems?.length) {
    return { totalDiscount: totalPrice, campaignDiscount, hasDiscount };
  }
  // Filter campaigns applicable for the given sell type
  const applicableCampaigns = campaignItems.filter((campaign) => {
    return !campaign.productSellType || campaign.productSellType === type;
  });
  for (const campaign of applicableCampaigns) {
    const { campaignType, campaignData } = campaign;
    switch (campaignType) {
      case CampaignType.Discount: {
        campaignDiscount = {
          CPQ: 1,
          maxDuplicates: campaignData.maxDuplicates,
        };
        const discount = applyDiscount(totalPrice, campaignData, quantity);
        hasDiscount = true;
        totalPrice -= discount;
        break;
      }
      case CampaignType.BuyXQuantityForTotalDiscountY: {
        const CPQ = campaignData.conditionalQuantity;
        campaignDiscount = {
          CPQ,
          maxDuplicates: campaignData.maxDuplicates,
        };

        const discount = applyBuyXForYDiscount(
          totalPrice,
          campaignData,
          price,
          quantity
        );
        hasDiscount = true;
        totalPrice -= discount;

        break;
      }
      case CampaignType.BuyXQuantityGetYQuantityWithDiscountZ: {
        const CPQ =
          campaignData.receivedQuantity + campaignData.conditionalQuantity;
        campaignDiscount = {
          CPQ,
          maxDuplicates: campaignData.maxDuplicates,
        };
        const discount = applyBuyXGetYDiscount(
          totalPrice,
          campaignData,
          price,
          quantity
        );
        hasDiscount = true;
        totalPrice -= discount;

        break;
      }
      default:
        break;
    }
  }
  return {
    totalDiscount: Math.max(totalPrice, 0),
    campaignDiscount,
    hasDiscount,
  };
};

export default DiscountCalculator;
export const GetItemDiscount = (product: Product, sellType: SellType) => {
  const { price, campaignItems, title } = product || {};
  const { quantityComparingToDefaultSellType, type } = sellType || {};

  const ItemTotalPrice = price * (quantityComparingToDefaultSellType || 1);
  let hasDiscount = false;
  let campaignDiscount = undefined;
  let totalPrice = ItemTotalPrice;
  if (!campaignItems?.length) {
    return { totalDiscount: totalPrice, campaignDiscount, hasDiscount };
  }
  // Filter campaigns applicable for the given sell type
  const applicableCampaigns = campaignItems.filter((campaign) => {
    return !campaign.productSellType || campaign.productSellType === type;
  });
  for (const campaign of applicableCampaigns) {
    const { campaignType, campaignData } = campaign;
    if (campaignType === CampaignType.Discount) {
      const discount = applyDiscount(
        totalPrice,
        campaignData,
        quantityComparingToDefaultSellType || 1
      );
      totalPrice = discount;
      hasDiscount = true;
    }
  }
  return {
    itemDiscount: Math.max(totalPrice, 0),
    hasDiscount,
  };
};

// TODO here should check and makes the changes
export const AddItemCampaignDiscount = (
  product: Product,
  quantity: number,
  sellType?: SellType,
  totalQuantity: number = 0
) => {
  const { campaignItems, title } = product || {};
  const { type } = sellType || {};

  // Find applicable campaigns for the product and sell type
  const applicableCampaigns = campaignItems.filter((campaign) => {
    return !campaign.productSellType || campaign.productSellType === type;
  });

  for (const campaign of applicableCampaigns) {
    const { campaignType, campaignData } = campaign || {};
    const {
      receivedQuantity,
      conditionalQuantity,
      maxDuplicates,
      discountType,
      discountValue,
    } = campaignData || {};

    // Check if the campaign is "Buy X Get Y Free"
    if (
      campaignType === CampaignType.BuyXQuantityGetYQuantityWithDiscountZ &&
      discountType === IDiscountType.Percentage &&
      discountValue === 100
    ) {
      // Calculate the number of free items to add
      const discountGroups = Math.floor(
        totalQuantity / (conditionalQuantity + receivedQuantity)
      );

      const eligibleSets = !maxDuplicates
        ? discountGroups
        : Math.min(discountGroups, maxDuplicates);
      // should check if totalQuantity has already gets the eligibleSets return 0 if not return the receivedQuantity

      const freeItemsToAdd = !maxDuplicates
        ? receivedQuantity
        : discountGroups >= maxDuplicates
        ? 0
        : receivedQuantity;
      // console.table({
      //   maxDuplicates,
      //   totalQuantity,
      //   receivedQuantity,
      //   discountGroups,
      //   conditionalQuantity,
      //   eligibleSets,
      //   eligibleSetsModTotal: totalQuantity % eligibleSets,
      //   freeItemsToAdd,
      // });
      return Math.max(freeItemsToAdd, 0);
    }
  }
};

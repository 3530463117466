// import { SellType } from "@/types";
// import { PriceBy } from "@/utils/Enums";
// import cn from "classnames";
// import React, { useEffect, useMemo, useState } from "react";

// type Size = "small" | "medium" | "large";

// const defaultSize: Size = "medium";

// interface MeasurementsSelectorProps {
//   size?: Size;
//   selectors: SellType[];
//   currentSelect?: SellType;
//   handleSelect?: (type: SellType) => void;
//   disabled?: boolean;
//   btnClassName?: string;
// }

// const MeasurementsSelector: React.FC<MeasurementsSelectorProps> = ({
//   size = defaultSize,
//   selectors,
//   currentSelect,
//   handleSelect = () => {},
//   disabled,
// }) => {
//   const [isActive, setIsActive] = useState<SellType>(selectors?.[0]);
//   const sizeClasses = {
//     small: "text-xs w-8 h-6",
//     medium: "text-sm w-12 h-8",
//     large: "text-md w-16 h-10",
//   };

//   useEffect(() => {
//     if (!currentSelect) return;

//     setIsActive(currentSelect);
//   }, [currentSelect]);
//   return (
//     <div
//       className={cn(
//         "flex items-center rounded-full border-[1px] border-primary overflow-hidden"
//       )}
//     >
//       {selectors?.slice(0, 2)?.map((type, index) => (
//         <button
//           onClick={() => handleSelect(type)}
//           disabled={disabled}
//           key={index}
//           className={cn(sizeClasses[size], {
//             "bg-primary text-primaryLight": isActive?.value === type.value,
//             "opacity-85": disabled,
//           })}
//         >
//           {type.text}
//         </button>
//       ))}
//     </div>
//   );
// };

// export default MeasurementsSelector;
import { SellType } from "@/types";
import cn from "classnames";
import React, { useEffect, useState } from "react";

type Size = "xSmall" | "small" | "medium" | "large";
type Theme = "default" | "light";

const defaultSize: Size = "medium";

interface MeasurementsSelectorProps {
  size?: Size;
  selectors: SellType[];
  currentSelect?: SellType;
  handleSelect?: (type: SellType) => void;
  disabled?: boolean;
  btnClassName?: string;
  theme?: Theme;
}

const MeasurementsSelector: React.FC<MeasurementsSelectorProps> = ({
  size = defaultSize,
  selectors,
  currentSelect,
  handleSelect = () => {},
  disabled,
  theme = "default",
}) => {
  const [isActive, setIsActive] = useState<SellType>(selectors?.[0]);
  const sizeClasses = {
    xSmall: "text-xs w-6 h-4",
    small: "text-xs w-8 h-6",
    medium: "text-sm w-12 h-8",
    large: "text-md w-16 h-10",
  };

  useEffect(() => {
    if (!currentSelect) return;
    setIsActive(currentSelect);
  }, [currentSelect]);

  const getButtonClasses = (type: SellType) => {
    const isSelected = isActive?.value === type.value;

    if (theme === "light") {
      return cn(sizeClasses[size], {
        "bg-white border-white text-primary font-semibold": isSelected,
        "text-white": !isSelected,
        "opacity-85": disabled,
      });
    } else {
      return cn(sizeClasses[size], {
        "bg-primary text-white": isSelected,
        "opacity-85": disabled,
      });
    }
  };

  return (
    <div
      className={cn(
        "flex items-center rounded-full overflow-hidden  border-[1px]",
        theme === "light" ? "border-white" : "border-black"
      )}
    >
      {selectors?.slice(0, 2)?.map((type, index) => (
        <button
          onClick={() => handleSelect(type)}
          disabled={disabled}
          key={index}
          className={getButtonClasses(type)}
        >
          {type.text}
        </button>
      ))}
    </div>
  );
};

export default MeasurementsSelector;

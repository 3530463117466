import React from "react";
import { Space } from "antd";
import { FaPlus, FaMinus } from "react-icons/fa6";
import Button from "./ui/button";
import cn from "classnames";

interface Props {
  quantity?: number | undefined;
  onDecrement: () => void;
  onIncrement: () => void;
  disableIncrement?: boolean;
  disableDecrement?: boolean;
  className?: string;
  isCard?: boolean;
  variant?: "default" | "text" | "primary";
  wrapperClassName?: string;
}

const Counter: React.FC<Props> = ({
  quantity,
  onDecrement,
  onIncrement,
  disableIncrement = false,
  disableDecrement = false,
  variant = "default",
  isCard,
  className = "",
  wrapperClassName = "",
}) => {
  return (
    <Space
      size="small"
      className={cn(
        "w-full flex items-center justify-between px-1",
        wrapperClassName
      )}
    >
      {!isCard || (isCard && quantity) ? (
        <>
          <Button
            type={variant}
            icon={<FaMinus color={isCard ? "white" : ""} size={15} />}
            onClick={onDecrement}
            className={`rounded-full ${className}`}
            disabled={disableDecrement}
          />
          <div
            className={cn("text-center min-w-9", {
              "bg-black text-bgLight p-2 max-md:min-w-10 rounded-full flex justify-center items-center":
                variant === "text",
              "bg-transparent": isCard,
            })}
          >
            <span
              className={cn("w-full", {
                "text-4xl font-semibold ": isCard,
              })}
            >
              {quantity}
            </span>
          </div>
        </>
      ) : null}

      <Button
        type={variant}
        icon={
          <FaPlus
            color={isCard ? "white" : ""}
            size={isCard && !quantity ? 30 : 15}
          />
        }
        onClick={onIncrement}
        className={`rounded-full ${className}`}
        disabled={disableIncrement}
      />
    </Space>
  );
};

export default Counter;
